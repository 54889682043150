<template>
  <auth-wrapper>
    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <v-card-title primary-title class="justify-center">Sign up for Rentify with</v-card-title>
    <v-img src="../assets/appsumo-logo.png" height="50px" contain></v-img>
    <v-card-text class="body-2 justify-center text-center">
      Add your AppSumo code to your existing Rentify account
    </v-card-text>
    <v-card-text class="mb-0 pb-0">
      <v-form v-model="valid" autocomplete="off">
        <v-row>
          <v-col cols="12" class="py-0">
            <text-field
                label="Email address"
                prepend-icon="mdi-email"
                :rules="[rules.required, rules.email]"
                v-model="form.email"
            >
            </text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <text-field
                label="Password"
                :type="showPass ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                :rules="[rules.required]"
                v-model="form.password"
            ></text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <text-field
                label="AppSumo Code"
                prepend-icon="mdi-store"
                :rules="[rules.required]"
                v-model="form.token"
            >
            </text-field>
          </v-col>
        </v-row>
      </v-form>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined rounded color="primary" :disabled="!valid" :loading="loading" @click="linkAccount">Link Account
      </v-btn>
    </v-card-actions>
    <v-card-text>Don't have a rentify account yet?
      <router-link :to="form.token ?  `/appsumo/${form.token}`: '/appsumo'" v-slot="{ href }">
        <a :href="href">Click here to create one</a>
      </router-link>
    </v-card-text>
  </auth-wrapper>
</template>

<script>
import {linkSumoUser} from '@/graphql'
import { user } from '@/graphql'
import {BACKEND_DOMAIN} from "../variables";
import Cookie from "js-cookie";

export default {
  name: 'LinkSumo',
  apollo: {user},
  data: () => ({
    valid: null,
    loading: false,
    showPass: false,
    form: {
      email: null,
      password: null,
      token: null
    },
    rules: {
      required: v => !!v || 'This field is required',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    },
    error: false,
    errorMessage: null
  }),
  mounted() {
    if (this.$route.params.token) {
      this.form.token = this.$route.params.token
    }
  },
  methods: {
    linkAccount() {
      this.loading = true
      this.$apollo.mutate({
        mutation: linkSumoUser,
        variables: this.form
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error: ', '')
        this.loading = false
      }).then(({data}) => {
        if (data) {
          const {token} = data.linkSumoUser;
          if (BACKEND_DOMAIN) {
            Cookie.set('JWT', token, {domain: BACKEND_DOMAIN});
          } else {
            Cookie.set('JWT', token);
          }
          this.$apollo.queries.user.refetch().then(() => {
            this.$emit('refreshUser')
            this.$router.push('/account');
          })
          this.loading = false;
        }
      })
    }
  }
}
</script>
